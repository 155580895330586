import {
   Box,
   Button,
   type ColorProps,
   Flex,
   type LayoutProps,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalOverlay,
   type ModalProps,
   Text,
} from '@chakra-ui/react';

import { Illustration } from '../Illustration';

interface IProps extends Omit<ModalProps, 'children'> {
   title?: string;
   description?: string | React.ReactNode | React.ReactNode[];
   bg?: ColorProps['color'];
   closeButtonBg?: ColorProps['color'];
   onCTAClick?: (e: React.MouseEvent) => void;
   ctaLabel?: string;
   ctaStatus?: 'error' | 'idle' | 'pending' | 'success';
   children?: React.ReactNode;
   textAlign?: 'left' | 'center';
   fullWidthBtn?: boolean;
   onCloseComplete?: () => void;
   backButton?: React.ReactNode;
   removeHorizontalPadding?: boolean;
   removeCloseBtn?: boolean;
   minHOverride?: LayoutProps['minH'];
   minWOverride?: LayoutProps['minW'];
   roundedFull?: boolean;
   showCornerGraphic?: boolean;
}

const BoxedModal: React.FC<IProps> = ({
   title,
   description,
   bg = 'white',
   closeButtonBg = 'neutral.200',
   children,
   isOpen,
   onClose,
   onCTAClick,
   ctaStatus,
   ctaLabel = 'Ok',
   onCloseComplete,
   textAlign,
   fullWidthBtn = false,
   backButton,
   removeHorizontalPadding,
   removeCloseBtn,
   minHOverride,
   minWOverride,
   roundedFull = false,
   showCornerGraphic = false,
   ...rest
}) => {
   return (
      <Modal
         isOpen={isOpen}
         onClose={onClose}
         isCentered
         onCloseComplete={onCloseComplete}
         {...rest}
      >
         <ModalOverlay />
         <ModalContent
            minW={minWOverride ?? { base: 'full', lg: '656px' }}
            w={minWOverride ?? { base: '100%', lg: 'auto' }}
            minH={
               minHOverride ?? {
                  base: 'calc(var(--app-height) - 20px) ',
                  lg: '420px',
               }
            }
            mb={0}
            mt={{ base: 5, lg: 0 }}
            bg={bg}
            borderRadius={12}
            borderBottomRadius={!roundedFull ? { base: 0, lg: 12 } : 12}
            textAlign={textAlign}
            overflow={{ base: 'scroll', lg: 'auto' }}
            maxH={{ lg: '90vh' }}
         >
            {backButton}
            {showCornerGraphic ? (
               <Illustration
                  src="/illustrations/three-circles-top-right.svg"
                  pos="absolute"
                  right={0}
                  top={0}
               />
            ) : null}

            {!removeCloseBtn && (
               <ModalCloseButton
                  mt={{ base: 2, lg: 6 }}
                  mr={{ base: 2, lg: 6 }}
                  borderRadius="50%"
                  backgroundColor={closeButtonBg}
                  width={12}
                  height={12}
                  zIndex={2}
               />
            )}
            <ModalBody display="flex">
               <Flex
                  pt={16}
                  px={{
                     base: removeHorizontalPadding ? 0 : 6,
                     lg: removeHorizontalPadding ? 12 : 16,
                  }}
                  pb={{ base: 5, lg: 10 }}
                  flexDirection="column"
                  justifyContent={{ base: 'flex-start', lg: 'space-between' }}
                  width="100%"
                  maxH={{ base: 'calc(var(--app-height) - 20px)', lg: '100%' }}
               >
                  <Flex
                     flexDir="column"
                     zIndex={2}
                     pb={{ base: 6, lg: 0 }}
                     flex={1}
                  >
                     <Box pos="relative" zIndex={2}>
                        {title ? (
                           <Text pb={3} size="leadTitle" fontWeight={500}>
                              {title}
                           </Text>
                        ) : null}
                        {description}
                     </Box>
                     {children}
                     {onCTAClick ? (
                        <Button
                           onClick={onCTAClick}
                           size={{ base: 'sm', lg: 'md' }}
                           mt={{ base: 'auto', lg: 10 }}
                           alignSelf={!fullWidthBtn ? 'flex-start' : 'auto'}
                           isDisabled={
                              ctaStatus === 'pending' || ctaStatus === 'success'
                           }
                           isLoading={ctaStatus === 'pending'}
                        >
                           {ctaLabel}
                        </Button>
                     ) : null}
                  </Flex>
               </Flex>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default BoxedModal;
