import { chakra, forwardRef, type HTMLChakraProps } from '@chakra-ui/react';
import type { IllustrationPath } from '@ikona/types/illustration-path';

export interface IllustrationProps extends Omit<HTMLChakraProps<'img'>, 'src'> {
   src: IllustrationPath;
}

export const Illustration = forwardRef<IllustrationProps, 'img'>(
   (props, ref) => (
      <chakra.img
         ref={ref}
         data-icon=""
         data-illustration=""
         alt=""
         {...props}
      />
   ),
);

export interface SvgIllustrationProps
   extends Omit<HTMLChakraProps<'image'>, 'href'> {
   href: IllustrationPath;
}

export const SvgIllustration = forwardRef<SvgIllustrationProps, 'image'>(
   (props, ref) => (
      <chakra.image ref={ref} data-icon="" data-illustration="" {...props} />
   ),
);
