// const sitterRes = await apiClient.getSitterInfo();
// const sitter = sitterRes.data;

import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export default function useMySitter() {
   const { data: sessionData } = useSession();
   const { data: sitter, ...rest } = useQuery({
      queryKey: queryKeys.sitter,
      queryFn: async () => {
         const response = await webApiClient.patients.getSitter();
         return response.body;
      },
      enabled: !!sessionData,
   });

   return {
      ...rest,
      sitter: sitter ?? null,
   };
}
