import { DateTime } from 'luxon';

const LOCALSTORAGE_DOSING_NUMBER_KEY = 'ads-number';
const LOCALSTORAGE_PLAYLIST_ID_KEY = 'ads-playlist-id';
const LOCALSTORAGE_SESSION_START_KEY = 'ads-session-time-start';
const LOCALSTORAGE_FLOW_START_KEY = 'ads-flow-time-start';
const LOCALSTORAGE_WELCOME_POPUP_SEEN_KEY = 'ads-welcome-popup-seen';

const adsLocalStorage = {
   setWelcomePopupSeen: (seen: boolean) => {
      return localStorage.setItem(
         LOCALSTORAGE_WELCOME_POPUP_SEEN_KEY,
         seen.toString(),
      );
   },

   isWelcomePopupSeen: () => {
      return (
         localStorage.getItem(LOCALSTORAGE_WELCOME_POPUP_SEEN_KEY) === 'true'
      );
   },

   setDosingNumber: (dosingSessionNumber: number) => {
      return localStorage.setItem(
         LOCALSTORAGE_DOSING_NUMBER_KEY,
         dosingSessionNumber.toString(),
      );
   },

   getDosingNumber: () => {
      return parseInt(
         window.localStorage.getItem(LOCALSTORAGE_DOSING_NUMBER_KEY) || '1',
      );
   },

   setPlaylistId: (playlistId: string) => {
      window.localStorage.setItem(LOCALSTORAGE_PLAYLIST_ID_KEY, playlistId);
   },

   getPlaylistId: () => {
      return window.localStorage.getItem(LOCALSTORAGE_PLAYLIST_ID_KEY);
   },

   setFlowTimeStart: (dateTime: Date) => {
      window.localStorage.setItem(
         LOCALSTORAGE_FLOW_START_KEY,
         dateTime.toString(),
      );
   },

   getFlowTimeStart: () => {
      return window.localStorage.getItem(LOCALSTORAGE_FLOW_START_KEY);
   },

   setSessionTimeStart: (dateTime: Date) => {
      window.localStorage.setItem(
         LOCALSTORAGE_SESSION_START_KEY,
         DateTime.fromJSDate(dateTime).toISO() ?? '',
      );
   },

   getSessionTimeStart: () => {
      const isoDate = window.localStorage.getItem(
         LOCALSTORAGE_SESSION_START_KEY,
      );
      if (!isoDate) {
         return null;
      }

      return DateTime.fromISO(isoDate);
   },

   clear: () => {
      window.localStorage.removeItem(LOCALSTORAGE_PLAYLIST_ID_KEY);
      window.localStorage.removeItem(LOCALSTORAGE_SESSION_START_KEY);
      window.localStorage.removeItem(LOCALSTORAGE_FLOW_START_KEY);
   },
};

export default adsLocalStorage;
