import { Tooltip, type TooltipProps } from '@chakra-ui/react';

import { Icon } from '../Icon';

export const InfoTooltip = (props: Omit<TooltipProps, 'children'>) => {
   return (
      <Tooltip
         textColor="white"
         label=""
         shouldWrapChildren
         hasArrow
         bg="text.primary"
         borderRadius="8px"
         placement="right"
         maxW="270px"
         py={2}
         ml={{ base: 2, lg: 0 }}
         px={{ base: 2, lg: 3 }}
         fontWeight={500}
         {...props}
      >
         <Icon
            name="info"
            pos="relative"
            w={4}
            h={4}
            color="background.primary"
         />
      </Tooltip>
   );
};
