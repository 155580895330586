import { FormControl, Grid, Input, type ModalProps } from '@chakra-ui/react';
import { CustomIconButton } from '@innerwell/chakra/buttons';
import { type ConfirmDosageDto, confirmDosageDtoSchema } from '@innerwell/dtos';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

import useMySitter from '@/hooks/react-query/useMySitter';
import { useZodForm } from '@/hooks/useZodForm';

import { formatDateNicely } from '@/utils/formatting';

import BoxedModal from './BoxedModal';
import CustomCheckbox from '../Devkit/CustomFormControl/CustomCheckbox/CustomCheckbox';
import CustomFormControl from '../Devkit/CustomFormControl/CustomFormControl';
import { Icon } from '../Icon';

interface IProps extends Omit<ModalProps, 'children'> {
   onFormSubmit: (data: ConfirmDosageDto) => void;
}

const ConfirmDosageModal: React.FC<IProps> = ({
   isOpen,
   onClose,
   onFormSubmit,
}) => {
   const { sitter } = useMySitter();
   const {
      handleSubmit,
      register,
      formState: { errors },
      control,
      reset,
      watch,
      setValue,
   } = useZodForm({
      schema: confirmDosageDtoSchema,
      defaultValues: {},
   });

   useEffect(() => {
      if (sitter) {
         setValue('sitter', {
            email: sitter.email,
            phone: sitter.phoneNumber,
            name: sitter.name,
            relationship: sitter.relationship,
            isEc: sitter.isEmergencyContact,
         });
      }
   }, [setValue, sitter]);

   const handleFormSubmit = (data: ConfirmDosageDto) => {
      onClose();
      onFormSubmit(data);

      reset({
         sitter: {
            email: sitter?.email,
            phone: sitter?.phoneNumber,
            name: sitter?.name,
            relationship: sitter?.relationship,
            isEc: sitter?.isEmergencyContact,
         },
      });
   };

   const isEcSameAsSitter = watch('sitter.isEc');

   return (
      <BoxedModal
         isOpen={isOpen}
         onClose={onClose}
         bg="background.primary"
         removeHorizontalPadding
         minWOverride={{ base: 'full', lg: '55rem' }}
      >
         <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid
               gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 50%)' }}
               gap={4}
               pr={{ lg: 4 }}
            >
               <CustomFormControl
                  label="When did you take the dose?*"
                  name="date"
                  isInvalid={!!errors.dosage?.date}
                  errorMsg={
                     errors.dosage?.date
                        ? (errors.dosage.date.message as string)
                        : undefined
                  }
                  css={{
                     '.react-datepicker-wrapper': {
                        width: '100%',
                     },
                  }}
               >
                  <Controller
                     control={control}
                     name="dosage.date"
                     render={({ field: { onChange, value } }) => {
                        const dateValue = value as unknown as string;

                        return (
                           <DatePicker
                              onChange={(date) => {
                                 if (date) {
                                    const dateTime =
                                       DateTime.fromJSDate(date).toISO();
                                    onChange(dateTime);
                                 }
                              }}
                              shouldCloseOnSelect
                              dateFormat="Pp"
                              placeholderText="Choose a date"
                              selected={
                                 dateValue
                                    ? DateTime.fromISO(dateValue).toJSDate()
                                    : null
                              }
                              // Readonly
                              onKeyDown={(e) => {
                                 e.preventDefault();
                              }}
                              value={
                                 value
                                    ? formatDateNicely(
                                         DateTime.fromISO(dateValue),
                                      )
                                    : ''
                              }
                              customInput={
                                 <Input w="full" type="text" id="date" />
                              }
                           />
                        );
                     }}
                  />
               </CustomFormControl>

               <CustomFormControl
                  label="Please confirm the dose (mg) you took*"
                  name="dosage"
                  isInvalid={!!errors.dosage?.please_confirm_the_dose}
                  errorMsg={
                     errors.dosage?.please_confirm_the_dose
                        ? (errors.dosage.please_confirm_the_dose
                             .message as string)
                        : undefined
                  }
               >
                  <Input
                     // type="number"
                     id="dosage"
                     {...register('dosage.please_confirm_the_dose')}
                     placeholder="250"
                  />
               </CustomFormControl>
            </Grid>

            <CustomFormControl
               label="What is the package lot that your medicine comes from?"
               name="lot"
               isInvalid={
                  !!errors.dosage?.[
                     'what-is-the-package-lot-that-your-medicine-comes-from'
                  ]
               }
               errorMsg={
                  errors.dosage?.[
                     'what-is-the-package-lot-that-your-medicine-comes-from'
                  ]
                     ? (errors.dosage[
                          'what-is-the-package-lot-that-your-medicine-comes-from'
                       ].message as string)
                     : undefined
               }
               helperText="The lot number can be found either on the back of the tablet blister package or underneath “Ketamine [dose]mg” after “Lot,” depending on the pharmacy."
            >
               <Input
                  type="text"
                  id="lot"
                  {...register(
                     'dosage.what-is-the-package-lot-that-your-medicine-comes-from',
                  )}
                  placeholder=""
               />
            </CustomFormControl>

            <Grid
               gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 50%)' }}
               gap={4}
               pr={{ lg: 4 }}
            >
               <CustomFormControl
                  label="Top Blood Pressure (systolic)"
                  name="blood_pressure_top"
                  isInvalid={
                     !!errors.bloodPressure?.what_is_the_top_number_systol
                  }
                  errorMsg={
                     errors.bloodPressure?.what_is_the_top_number_systol
                        ? (errors.bloodPressure?.what_is_the_top_number_systol
                             .message as string)
                        : undefined
                  }
               >
                  <Input
                     type="text"
                     id="blood_pressure_top"
                     {...register(
                        'bloodPressure.what_is_the_top_number_systol',
                     )}
                     placeholder="Top number of your blood pressure (systolic)"
                  />
               </CustomFormControl>

               <CustomFormControl
                  label="Bottom Blood Pressure (diastolic)"
                  name="blood_pressure_bottom"
                  isInvalid={
                     !!errors.bloodPressure?.what_is_the_bottom_number_dia
                  }
                  errorMsg={
                     errors.bloodPressure?.what_is_the_bottom_number_dia
                        ? (errors.bloodPressure?.what_is_the_bottom_number_dia
                             .message as string)
                        : undefined
                  }
               >
                  <Input
                     type="text"
                     id="blood_pressure_bottom"
                     {...register(
                        'bloodPressure.what_is_the_bottom_number_dia',
                     )}
                     placeholder="Bottom number of your blood pressure (diastolic)"
                  />
               </CustomFormControl>
            </Grid>

            <Grid
               gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 50%)' }}
               gap={4}
               pr={{ lg: 4 }}
            >
               <CustomFormControl
                  label="Sitter name*"
                  name="sitter_name"
                  isInvalid={!!errors.sitter?.name}
                  errorMsg={
                     errors.sitter?.name
                        ? (errors.sitter.name.message as string)
                        : undefined
                  }
               >
                  <Input
                     type="text"
                     id="sitter_name"
                     {...register('sitter.name')}
                  />
               </CustomFormControl>

               <CustomFormControl
                  label="Sitter email*"
                  name="sitter_email"
                  isInvalid={!!errors.sitter?.email}
                  errorMsg={
                     errors.sitter?.email
                        ? (errors.sitter.email.message as string)
                        : undefined
                  }
               >
                  <Input
                     type="email"
                     id="sitter_email"
                     {...register('sitter.email')}
                  />
               </CustomFormControl>
            </Grid>

            <Grid
               gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 50%)' }}
               gap={4}
               pr={{ lg: 4 }}
            >
               <CustomFormControl
                  label="Sitter phone*"
                  name="sitter_phone"
                  isInvalid={!!errors.sitter?.phone}
                  errorMsg={
                     errors.sitter?.phone
                        ? (errors.sitter.phone.message as string)
                        : undefined
                  }
               >
                  <Input
                     type="tel"
                     id="sitter_phone"
                     {...register('sitter.phone')}
                  />
               </CustomFormControl>

               <CustomFormControl
                  label="Sitter relationship*"
                  name="sitter_relationship"
                  isInvalid={!!errors.sitter?.relationship}
                  errorMsg={
                     errors.sitter?.relationship
                        ? (errors.sitter.relationship.message as string)
                        : undefined
                  }
               >
                  <Input
                     type="text"
                     id="sitter_relationship"
                     {...register('sitter.relationship')}
                  />
               </CustomFormControl>
            </Grid>

            <FormControl mt={2} py={3}>
               <CustomCheckbox
                  variant="dark"
                  checked={String(isEcSameAsSitter) === 'true'}
                  isChecked={String(isEcSameAsSitter) === 'true'}
                  {...register('sitter.isEc')}
                  lineHeight={1.3}
                  color="white"
               >
                  Sitter is emergency contact
               </CustomCheckbox>
            </FormControl>

            {!isEcSameAsSitter && (
               <>
                  <Grid
                     gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 50%)' }}
                     gap={4}
                     pr={{ lg: 4 }}
                  >
                     <CustomFormControl
                        label="Emergency contact name*"
                        name="ec_name"
                        isInvalid={!!errors.emergencyContact?.name}
                        errorMsg={
                           errors.emergencyContact?.name
                              ? (errors.emergencyContact.name.message as string)
                              : undefined
                        }
                     >
                        <Input
                           type="text"
                           id="ec_name"
                           {...register('emergencyContact.name')}
                        />
                     </CustomFormControl>

                     <CustomFormControl
                        label="Emergency contact email*"
                        name="ec_email"
                        isInvalid={!!errors.emergencyContact?.email}
                        errorMsg={
                           errors.emergencyContact?.email
                              ? (errors.emergencyContact.email
                                   .message as string)
                              : undefined
                        }
                     >
                        <Input
                           type="email"
                           id="ec_email"
                           {...register('emergencyContact.email')}
                        />
                     </CustomFormControl>
                  </Grid>

                  <Grid
                     gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 50%)' }}
                     gap={4}
                     pr={{ lg: 4 }}
                  >
                     <CustomFormControl
                        label="Emergency contact phone*"
                        name="ec_phone"
                        isInvalid={!!errors.emergencyContact?.phone}
                        errorMsg={
                           errors.emergencyContact?.phone
                              ? (errors.emergencyContact.phone
                                   .message as string)
                              : undefined
                        }
                     >
                        <Input
                           type="tel"
                           id="ec_phone"
                           {...register('emergencyContact.phone')}
                        />
                     </CustomFormControl>

                     <CustomFormControl
                        label="Emergency contact relationship*"
                        name="ec_relationship"
                        isInvalid={!!errors.emergencyContact?.relationship}
                        errorMsg={
                           errors.emergencyContact?.relationship
                              ? (errors.emergencyContact.relationship
                                   .message as string)
                              : undefined
                        }
                     >
                        <Input
                           type="text"
                           id="ec_relationship"
                           {...register('emergencyContact.relationship')}
                        />
                     </CustomFormControl>
                  </Grid>
               </>
            )}

            <CustomIconButton
               icon={<Icon name="chevron-right" w={5} h={5} />}
               mt={6}
               type="submit"
            >
               Confirm
            </CustomIconButton>
         </form>
      </BoxedModal>
   );
};

export default ConfirmDosageModal;
