import { type ConfirmDosageDto, type DosageConfirmationDto } from '@innerwell/dtos';
import { getErrorMessage, getUtcDateFromTz } from '@innerwell/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { handleSentryException } from '@/utils/sentry';

import useThemedToast from '../useThemedToast';

import { queryKeys } from '@/types/query-keys';

export const useConfirmDosage = () => {
   const { toastSuccess, toastError } = useThemedToast();
   const queryClient = useQueryClient();

   return useMutation({
      mutationFn: async (data: ConfirmDosageDto) => {
         const date = getUtcDateFromTz(data.dosage.date);

         const response = await webApiClient.patients.confirmDosage({
            body: {
               ...data,
               dosage: {
                  ...data.dosage,
                  date: date.toISO(),
               },
            },
         });

         return response.body;
      },

      onMutate: (data) => {
         queryClient.setQueryData<DosageConfirmationDto[]>(
            queryKeys.dosageConfirmations,
            (existingData) => {
               const newData = {
                  date: data.dosage.date.toISO(),
                  dosage: data.dosage.please_confirm_the_dose,
                  lot: data.dosage[
                     'what-is-the-package-lot-that-your-medicine-comes-from'
                  ],
               };

               if (!existingData || existingData?.length === 0) {
                  return [newData];
               }

               return [...existingData, newData];
            },
         );
      },
      onError: (err) => {
         const errMsg = getErrorMessage(err);
         handleSentryException(err);
         toastError(errMsg);
      },
      onSuccess: () => {
         toastSuccess('Offline experience successfully tracked.');

         queryClient.refetchQueries({
            queryKey: queryKeys.sitter,
         });
         queryClient.refetchQueries({
            queryKey: queryKeys.moodTracking,
         });
         queryClient.refetchQueries({
            queryKey: queryKeys.dosingHistory,
         });
         queryClient.refetchQueries({
            queryKey: queryKeys.dosageConfirmations,
         });
      },
   });
};
