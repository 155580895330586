import { Checkbox, type CheckboxProps } from '@chakra-ui/react';

import { Icon } from '@/components/Icon';
import { forwardRef } from 'react';

const CustomCheckbox = forwardRef<
   HTMLInputElement,
   Omit<CheckboxProps, 'type' | 'checked'> & {
      checked?: string | boolean;
   }
>(({ checked, ...rest }, ref) => {
   return (
      <Checkbox
         ref={ref}
         value="true"
         icon={
            <Icon
               name="checkmark"
               opacity={rest.isChecked || checked ? 1 : 0}
               color={rest.variant === 'dark' ? 'white' : 'text.primary'}
               boxSize={4}
            />
         }
         checked={checked === 'true' || checked === true}
         {...rest}
      />
   );
});

CustomCheckbox.displayName = '';

export default CustomCheckbox;
