import {
   type AvailablePrescribingCliniciansResponseDto,
   type ClinicianResponseDto,
} from '@innerwell/dtos';
import { DateTime, Duration } from 'luxon';

export const formatClinicianName = (
   clinician: Pick<
      AvailablePrescribingCliniciansResponseDto,
      'firstName' | 'lastName' | 'title' | 'imageUrl' | 'zoomId'
   >,
) => {
   const { firstName, lastName, title } = clinician;

   return `${firstName} ${lastName}${title === 'N/A' ? '' : `, ${title}`}`;
};

export const padWithLeadingZero = (num: number | string, length = 2) => {
   return String(num).padStart(length, '0');
};

export const getTimezone = () => {
   return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDiscountAmountFromCategories = (catNames: string[]) => {
   const discountDigits = catNames
      .map((catName) => catName.match(/\d+/g)?.[0])
      .filter(Boolean)
      .map((num) => parseInt(num || '0'));

   if (discountDigits.length === 0) {
      return 0;
   }

   return Math.max(...discountDigits);
};

export const formatEligibityErrorMessage = (errorMessage: string) => {
   if (
      errorMessage.includes('Invalid') &&
      errorMessage.includes('Insured Name')
   ) {
      return `The name you entered does not match our records. Please double-check and re-enter
         your name to verify your insurance membership.`;
   }

   if (errorMessage.includes('Birth Date')) {
      return `The birth date you entered does not match our records. Please double-check and re-enter
         your birth date to verify your insurance membership.`;
   }

   if (/invalid\/missing subscriber\/insured id/i.test(errorMessage)) {
      return `Our system doesn’t recognize this insurance information. Please double-check that you have entered everything correctly to verify your eligibility.`;
   }

   return errorMessage;
};

export const prettifyCurrency = (
   amount: string | number,
   fractions?: number,
) => {
   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: fractions,
   });

   const amountFloat = typeof amount === 'string' ? parseFloat(amount) : amount;

   const formatted = formatter.format(amountFloat);

   // Add additional 0 on single decimals
   if (amountFloat % 1 !== 0 && fractions === 1 && formatted.includes('.')) {
      return `${formatted}0`;
   }

   return formatted;
};

export const formatDuration = (durationInSeconds?: number) => {
   if (durationInSeconds === 0 || !durationInSeconds) {
      return '00:00';
   }

   return Duration.fromMillis(durationInSeconds * 1000)
      .shiftTo('minutes', 'seconds')
      .toFormat('mm:ss');
};

export type FormattedClinician = (
   | AvailablePrescribingCliniciansResponseDto
   | ClinicianResponseDto
) & {
   fullName: string;
   avatar: string;
};

export const formatClinician = (
   clinician: AvailablePrescribingCliniciansResponseDto | ClinicianResponseDto,
): FormattedClinician => {
   const { firstName, lastName, title, imageUrl } = clinician;

   return {
      fullName: `${firstName} ${lastName}${
         title === 'N/A' ? '' : `, ${title}`
      }`,
      avatar: imageUrl || '/images/placeholder-clinician.png',
      ...clinician,
   };
};

export const formatTimeRange = ({ from, to }: { from: string; to: string }) => {
   return `${DateTime.fromISO(from).toFormat('h:mm')} – ${DateTime.fromISO(
      to,
   ).toFormat('h:mma')}`;
};

export const formatTime = (dateTime: DateTime) => {
   return dateTime.toFormat('hh:mm a');
};

export const formatDateTime = (dateTime: DateTime) => {
   return dateTime.toFormat('LLLL d, h:mm a');
};

export const formatDateNicelyShort = (
   dateTime: DateTime,
   options: {
      includeYear?: boolean;
   } = {
      includeYear: true,
   },
) => {
   const { includeYear } = options;
   return dateTime.toFormat(`ccc d LLL ${includeYear ? 'yy' : ''}`);
};

export const formatDateNicely = (
   date: string | DateTime,
   options: {
      includeYear?: boolean;
   } = {
      includeYear: true,
   },
) => {
   const { includeYear } = options;
   const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : date;

   return dateTime.toFormat(`EEEE, MMM dd${includeYear ? ', yyyy' : ''}`);
};

export const removeBracketsFromString = (text: string) => {
   return text.replace(/ *\([^)]*\) */g, '');
};
